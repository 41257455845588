import React from 'react';

export default {
  home: (
    <svg width="23" height="24" viewBox="0 0 23 24">
      <path
        fill="#555"
        d="M7.95808665,21.4797568 L7.95808665,14.2926972 C7.95808665,13.872855 8.29729322,13.5325056 8.71572573,13.5325056 L14.7768383,13.5325056 C15.1952708,13.5325056 15.5344774,13.872855 15.5344774,14.2926972 L15.5344774,21.4800639 L18.3333605,21.4801773 C18.4412665,21.4760176 18.5251665,21.4671569 18.58271,21.4596448 C18.588514,21.3995182 18.5915777,21.3235793 18.5907536,21.2318213 L18.5909124,14.2893672 C18.590922,13.869525 18.9301364,13.5291834 19.3485689,13.5291931 C19.7670014,13.5292027 20.1062001,13.8695599 20.1061905,14.2894021 L20.1059996,21.2248359 C20.1119888,21.8771673 19.9896563,22.3494462 19.5993559,22.6516032 C19.3010239,22.8825616 18.9057568,22.9796164 18.3624477,23 L4.48346492,22.9997015 C3.86961191,22.9824374 3.38486601,22.8118189 3.07125061,22.4182996 C2.79329968,22.0695315 2.68322544,21.5965073 2.68425496,20.9934992 L2.68424909,14.2893853 C2.68424872,13.8695431 3.02345499,13.5291934 3.4418875,13.5291931 C3.86032001,13.5291927 4.19952687,13.8695418 4.19952724,14.289384 L4.199532,20.9947994 C4.19904359,21.2811808 4.23526541,21.4410529 4.2538782,21.4676906 C4.24030246,21.4480656 4.31738234,21.474056 4.50469304,21.4796169 L7.95808665,21.4797568 Z M9.4733648,21.4798183 L14.0191993,21.4800025 L14.0191993,15.0528888 L9.4733648,15.0528888 L9.4733648,21.4798183 Z M2.28332829,11.4133771 C1.98200714,11.7046849 1.50237937,11.6957445 1.21204968,11.3934082 C0.921719999,11.0910719 0.930630346,10.6098283 1.2319515,10.3185205 L10.4507678,1.41136668 C10.7833916,1.14885042 11.1310579,1 11.4999998,1 C11.9102569,1 12.2738276,1.19453217 12.5845816,1.51087139 L21.7658664,10.3164197 C22.0683439,10.6065186 22.0791684,11.0877227 21.7900436,11.3912192 C21.5009189,11.6947157 21.0213305,11.7055767 20.718853,11.4154779 L11.5218713,2.5944308 C11.4545418,2.52599021 11.4440624,2.52038312 11.4999998,2.52038312 C11.514622,2.52038312 11.4750696,2.53731716 11.4448602,2.55629655 L2.28332829,11.4133771 Z"
      />
    </svg>
  ),
  component: (
    <svg viewBox="0 0 1041 1024" width="20" height="20">
      <path
        d="M133.498105 661.882904h31.175644v195.047619a34.373146 34.373146 0 0 0 34.373146 34.373146h109.514441a34.373146 34.373146 0 1 0 0-68.746292h-75.141295V627.509758a35.172521 35.172521 0 0 0-34.373146-35.172521h-65.54879a63.950039 63.950039 0 0 1 0-127.900079h65.54879a35.172521 35.172521 0 0 0 34.373146-35.172521V233.417642h195.846994a35.172521 35.172521 0 0 0 35.172522-34.373146v-65.54879a63.950039 63.950039 0 0 1 127.900078 0v65.54879a35.172521 35.172521 0 0 0 35.172521 34.373146h195.047619v74.341921a34.373146 34.373146 0 0 0 68.746292 0V199.044496a34.373146 34.373146 0 0 0-34.373146-34.373145H661.885302v-31.175645a133.495706 133.495706 0 0 0-266.991413 0v31.175645H199.046895a34.373146 34.373146 0 0 0-34.373146 34.373145v195.846995h-31.175644A132.696331 132.696331 0 0 0 0.002398 527.587822a133.495706 133.495706 0 0 0 133.495707 134.295082z"
        fill="#6F737E"
      />
      <path
        d="M1004.817386 840.943013a79.937549 79.937549 0 1 1 0-159.875097 34.373146 34.373146 0 0 0 34.373146-32.774395V533.982826a34.373146 34.373146 0 0 0-34.373146-34.373146H879.315435v-10.391881a103.119438 103.119438 0 1 0-206.238876 0v10.391881H549.972734a35.172521 35.172521 0 0 0-35.172522 34.373146v124.702576h-10.391881a103.119438 103.119438 0 1 0 0 206.238876h10.391881v124.702576a35.172521 35.172521 0 0 0 35.172522 34.373146H663.484053a34.373146 34.373146 0 0 0 34.373146-34.373146 79.937549 79.937549 0 0 1 159.875098 0 34.373146 34.373146 0 0 0 34.373146 34.373146h114.310694a34.373146 34.373146 0 0 0 34.373146-34.373146V879.313037a34.373146 34.373146 0 0 0-35.971897-38.370024z"
        fill="#6F737E"
      />
    </svg>
  ),
  github: (
    <svg viewBox="0 0 1049 1024" width="20" height="20">
      <path
        d="M524.979332 0C234.676191 0 0 234.676191 0 524.979332c0 232.068678 150.366597 428.501342 358.967656 498.035028 26.075132 5.215026 35.636014-11.299224 35.636014-25.205961 0-12.168395-0.869171-53.888607-0.869171-97.347161-146.020741 31.290159-176.441729-62.580318-176.441729-62.580318-23.467619-60.841976-58.234462-76.487055-58.234463-76.487055-47.804409-32.15933 3.476684-32.15933 3.476685-32.15933 53.019436 3.476684 80.83291 53.888607 80.83291 53.888607 46.935238 79.963739 122.553122 57.365291 152.97411 43.458554 4.345855-33.897672 18.252593-57.365291 33.028501-70.402857-116.468925-12.168395-239.022047-57.365291-239.022047-259.012982 0-57.365291 20.860106-104.300529 53.888607-140.805715-5.215026-13.037566-23.467619-66.926173 5.215027-139.067372 0 0 44.327725-13.906737 144.282399 53.888607 41.720212-11.299224 86.917108-17.383422 131.244833-17.383422s89.524621 6.084198 131.244833 17.383422C756.178839 203.386032 800.506564 217.29277 800.506564 217.29277c28.682646 72.1412 10.430053 126.029806 5.215026 139.067372 33.897672 36.505185 53.888607 83.440424 53.888607 140.805715 0 201.64769-122.553122 245.975415-239.891218 259.012982 19.121764 16.514251 35.636014 47.804409 35.636015 97.347161 0 70.402857-0.869171 126.898978-0.869172 144.282399 0 13.906737 9.560882 30.420988 35.636015 25.205961 208.601059-69.533686 358.967656-265.96635 358.967655-498.035028C1049.958663 234.676191 814.413301 0 524.979332 0z"
        fill="#191717"
      />
      <path
        d="M199.040177 753.571326c-0.869171 2.607513-5.215026 3.476684-8.691711 1.738342s-6.084198-5.215026-4.345855-7.82254c0.869171-2.607513 5.215026-3.476684 8.691711-1.738342s5.215026 5.215026 4.345855 7.82254z m-6.953369-4.345856M219.900283 777.038945c-2.607513 2.607513-7.82254 0.869171-10.430053-2.607514-3.476684-3.476684-4.345855-8.691711-1.738342-11.299224 2.607513-2.607513 6.953369-0.869171 10.430053 2.607514 3.476684 4.345855 4.345855 9.560882 1.738342 11.299224z m-5.215026-5.215027M240.760389 807.459932c-3.476684 2.607513-8.691711 0-11.299224-4.345855-3.476684-4.345855-3.476684-10.430053 0-12.168395 3.476684-2.607513 8.691711 0 11.299224 4.345855 3.476684 4.345855 3.476684 9.560882 0 12.168395z m0 0M269.443034 837.011749c-2.607513 3.476684-8.691711 2.607513-13.906737-1.738342-4.345855-4.345855-6.084198-10.430053-2.607513-13.037566 2.607513-3.476684 8.691711-2.607513 13.906737 1.738342 4.345855 3.476684 5.215026 9.560882 2.607513 13.037566z m0 0M308.555733 853.526c-0.869171 4.345855-6.953369 6.084198-13.037566 4.345855-6.084198-1.738342-9.560882-6.953369-8.691711-10.430053 0.869171-4.345855 6.953369-6.084198 13.037566-4.345855 6.084198 1.738342 9.560882 6.084198 8.691711 10.430053z m0 0M351.145116 857.002684c0 4.345855-5.215026 7.82254-11.299224 7.82254-6.084198 0-11.299224-3.476684-11.299224-7.82254s5.215026-7.82254 11.299224-7.82254c6.084198 0 11.299224 3.476684 11.299224 7.82254z m0 0M391.126986 850.049315c0.869171 4.345855-3.476684 8.691711-9.560882 9.560882-6.084198 0.869171-11.299224-1.738342-12.168395-6.084197-0.869171-4.345855 3.476684-8.691711 9.560881-9.560882 6.084198-0.869171 11.299224 1.738342 12.168396 6.084197z m0 0"
        fill="#191717"
      />
    </svg>
  ),
  issue: (
    <svg className="issue" viewBox="0 0 1024 1024" width="23" height="23">
      <path
        d="M1022.976 698.368c0-58.88-24.064-113.664-64.512-155.648-44.544 175.616-223.744 307.2-438.784 310.784 51.712 55.296 131.072 90.624 219.648 90.624 21.504 0 41.984-2.048 61.952-6.144 54.784 75.776 121.856 72.192 121.856 72.192-24.064-14.848-27.136-69.632-26.112-108.032 76.288-44.032 125.952-119.296 125.952-203.776z m0 0"
        fill="#558EFF"
      ></path>
      <path
        d="M458.24 13.824C205.312 13.824 0 191.488 0 410.624c0 137.216 80.384 258.048 202.24 329.216 1.536 61.44-3.584 150.016-41.984 174.592 0 0 108.544 5.632 197.12-116.736 32.256 6.144 66.048 9.728 100.352 9.728H465.92c215.04-3.584 394.24-135.168 438.784-310.784 7.168-27.648 10.752-56.32 10.752-86.016 0.512-219.136-204.288-396.8-457.216-396.8zM256 462.848c-33.792 0-60.928-27.136-60.928-60.928 0-33.792 27.136-60.928 60.928-60.928 33.792 0 60.928 27.136 60.928 60.928 0 33.792-27.136 60.928-60.928 60.928z m202.24 0c-33.792 0-60.928-27.136-60.928-60.928 0-33.792 27.136-60.928 60.928-60.928 33.792 0 60.928 27.136 60.928 60.928 0 33.792-27.136 60.928-60.928 60.928z m202.24 0c-33.792 0-60.928-27.136-60.928-60.928 0-33.792 27.136-60.928 60.928-60.928 33.792 0 60.928 27.136 60.928 60.928 0 33.792-27.136 60.928-60.928 60.928z m0 0"
        fill="#549EFF"
      ></path>
    </svg>
  ),
  gitee: (
    <svg className="gitee" viewBox="0 0 1024 1024" width="200" height="200">
      <path
        d="M512 1024C229.222 1024 0 794.778 0 512S229.222 0 512 0s512 229.222 512 512-229.222 512-512 512z m259.149-568.883h-290.74a25.293 25.293 0 0 0-25.292 25.293l-0.026 63.206c0 13.952 11.315 25.293 25.267 25.293h177.024c13.978 0 25.293 11.315 25.293 25.267v12.646a75.853 75.853 0 0 1-75.853 75.853h-240.23a25.293 25.293 0 0 1-25.267-25.293V417.203a75.853 75.853 0 0 1 75.827-75.853h353.946a25.293 25.293 0 0 0 25.267-25.292l0.077-63.207a25.293 25.293 0 0 0-25.268-25.293H417.152a189.62 189.62 0 0 0-189.62 189.645V771.15c0 13.977 11.316 25.293 25.294 25.293h372.94a170.65 170.65 0 0 0 170.65-170.65V480.384a25.293 25.293 0 0 0-25.293-25.267z"
        fill="#C71D23"
      ></path>
    </svg>
  ),
  plugin: (
    <svg viewBox="0 0 1024 1024" width="24" height="24">
      <path d="M937.6 251.3L538 69.7c-11.5-5.1-24.6-5.1-36.1 0L102.4 251.3c-22.4 9.9-32.5 32.2-22.6 54.6 4.5 10.1 12.5 15.1 22.6 19.6L502 506.1c11.5 5.1 24.6 5.1 36.1 0l399.6-180.6c22.4-9.9 32.5-33.2 22.6-55.6-4.6-10.1-12.6-14.1-22.7-18.6zM497.6 723.4l-398.7-165c-18.4-7.6-27.1-28.7-19.5-47 7.6-18.4 28.7-27.1 47-19.5l398.7 165c18.4 7.6 27.1 28.7 19.5 47-7.6 18.3-28.6 27.1-47 19.5z" />
      <path d="M526.4 723.4l398.7-165c18.4-7.6 27.1-28.7 19.5-47-7.6-18.4-28.7-27.1-47-19.5l-398.7 165c-18.4 7.6-27.1 28.7-19.5 47 7.6 18.3 28.6 27.1 47 19.5z" />
      <path d="M497.6 955.4l-398.7-165c-18.4-7.6-27.1-28.7-19.5-47 7.6-18.4 28.7-27.1 47-19.5l398.7 165c18.4 7.6 27.1 28.7 19.5 47-7.6 18.3-28.6 27.1-47 19.5z" />
      <path d="M526.4 955.4l398.7-165c18.4-7.6 27.1-28.7 19.5-47-7.6-18.4-28.7-27.1-47-19.5l-398.7 165c-18.4 7.6-27.1 28.7-19.5 47 7.6 18.3 28.6 27.1 47 19.5z" />
    </svg>
  ),
};
