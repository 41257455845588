export var openFileDialog = inputRef => {
  if (inputRef.current) inputRef.current.click();
};
export var getAcceptTypeString = accept => {
  return accept && accept.length > 0 ? accept.map(item => "." + item).join(', ') : 'image/*';
};
export var getBase64 = file => {
  var reader = new FileReader();
  return new Promise(resolve => {
    reader.addEventListener('load', () => resolve(String(reader.result)));
    reader.readAsDataURL(file);
  });
};
export var getListFiles = (files, dataURLKey) => {
  var promiseFiles = [];
  for (var i = 0; i < files.length; i += 1) {
    promiseFiles.push(getBase64(files[i]));
  }
  return Promise.all(promiseFiles).then(fileListBase64 => {
    var fileList = fileListBase64.map((base64, index) => ({
      [dataURLKey]: base64,
      file: files[index],
      name: files[index].name
    }));
    return fileList;
  });
};
export var isUploadType = type => {
  return ['picture', 'text', 'card'].includes(type);
};