import _extends from "@babel/runtime/helpers/extends";
/**
 * Get colspan number
 * @param {Array} date
 */
function getColspanNum(data, num) {
  if (data === void 0) {
    data = [];
  }
  if (num === void 0) {
    num = 1;
  }
  var childs = [];
  for (var i = 0; i < data.length; i += 1) {
    if (data[i].children) {
      childs = childs.concat(data[i].children || []);
    }
  }
  if (childs && childs.length > 0) {
    num = getColspanNum(childs, num + 1);
  }
  return num;
}

/**
 * Get rowspan number
 * @param {Array} date
 */
function getRowspanNum(data, child) {
  if (data === void 0) {
    data = [];
  }
  if (child === void 0) {
    child = [];
  }
  var childs = [];
  for (var i = 0; i < data.length; i += 1) {
    if (!data[i].children) {
      childs.push(data[i]);
    } else if (data[i].children && data[i].children.length > 0) {
      childs = childs.concat(getRowspanNum(data[i].children, child));
    }
  }
  return childs;
}
/**
 * JSON Array => Array
 * @param {Array} date
 */
export function getLevelItems(data, result) {
  if (!result) {
    result = {
      header: [],
      render: {}
    };
  }
  if (result && !result.header) {
    result.header = [];
  }
  if (result && !result.render) {
    result.render = {};
  }
  var child = [];
  var levelTop = [];
  for (var i = 0; i < data.length; i += 1) {
    if (data[i].render && data[i].key) {
      result.render[data[i].key] = data[i].render;
    }
    if (data[i].ellipsis && data[i].key) {
      if (!result.ellipsis) result.ellipsis = {};
      result.ellipsis[data[i].key] = true;
    }
    if (result.header.length === 0) {
      // Calculation rowspan
      if (data[i].children && data[i].children && data[i].children.length > 0) {
        data[i].colSpan = getRowspanNum(data[i].children).length;
      }
      levelTop.push(data[i]);
    }
    if (data[i] && data[i].children) {
      child = child.concat(data[i].children.map(item => {
        // Calculation rowspan
        if (item.children && item.children.length > 0) {
          item.colSpan = getRowspanNum(item.children).length;
        }
        return item;
      }));
    }
  }
  // level 1
  if (result.header.length === 0) {
    var num = getColspanNum(levelTop);
    result.header.push(levelTop.map(item => {
      if (num === 1) return item;
      if (!item.children || item.children && item.children.length === 0) {
        item.rowSpan = num;
      }
      return item;
    }));
  }
  if (child && child.length > 0) {
    var _num = getColspanNum(child);
    result.header.push(child.map(item => {
      if (_num === 1) return item;
      if (!item.children || item.children && item.children.length === 0) {
        item.rowSpan = _num;
      }
      return item;
    }));
    result = getLevelItems(child, result);
  }
  return result;
}

/**
 * Get all columns keys
 * @param {Array} data
 */
export function getAllColumnsKeys(data, keys) {
  if (keys === void 0) {
    keys = [];
  }
  for (var i = 0; i < data.length; i += 1) {
    if (data[i].children) {
      keys = keys.concat(getAllColumnsKeys(data[i].children || []));
    } else if (data[i].key) {
      keys.push(data[i]);
    } else {
      keys.push(_extends({}, data[i], {
        key: i.toString()
      }));
    }
  }
  return keys;
}
export function locationFixed(fixed, location, index) {
  var _location$index, _location$index2;
  if (!fixed) return {};
  if (fixed === 'right') return {
    right: (_location$index = location[index]) == null ? void 0 : _location$index.right
  };
  return {
    left: (_location$index2 = location[index]) == null ? void 0 : _location$index2.left
  };
}